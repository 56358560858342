import React from 'react'
import '../styles/Footer.css'
import { FaYoutube } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-bottom">
        <div className="footer-content">
          <p>&copy; {new Date().getFullYear()} The Lost Loft. All rights reserved.</p>
          <a 
            href="https://www.youtube.com/@TheLostLoftChicago" 
            target="_blank" 
            rel="noopener noreferrer"
            className="social-link"
          >
            <FaYoutube size={24} />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
