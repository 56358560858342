import { useState, useEffect } from 'react';
import '../styles/CurrentStreams.css';
import { fetchFromApi, API_ENDPOINTS } from '../config/api';
import { FaTwitch } from 'react-icons/fa';
import { Link, Typography, Box } from '@mui/material';

const CurrentStreams = () => {
  const [activeStream, setActiveStream] = useState(null);
  const [error, setError] = useState(null);

  const channelDisplayNames = {
    'groovepointe': 'GroovePointe',
    'plumsaucedj': 'PlumSauce',
    'ferluno': 'ferlUno',
    'thelostloftchicago': 'TheLostLoftChicago'
  };

  useEffect(() => {
    const checkStreams = async () => {
      try {
        setError(null);
        const liveChannels = await fetchFromApi(API_ENDPOINTS.checkTwitchStreams);
        if (liveChannels.length > 0) {
          // Transform the channel name
          const channel = liveChannels[0];
          const channelName = channel.url.split('/').pop();
          setActiveStream({
            ...channel,
            name: channelDisplayNames[channelName] || channelName
          });
        } else {
          setActiveStream(null);
        }
      } catch (error) {
        console.error('Error checking streams:', error);
        setError(error.message);
        setActiveStream(null);
      }
    };

    checkStreams();
    const interval = setInterval(checkStreams, 60000); // Check every minute
    
    return () => clearInterval(interval);
  }, []);

  if (error) {
    console.error('Stream check error:', error);
    return null;
  }

  if (!activeStream) return null;

  // Extract channel name from URL (e.g., 'GroovePointe' from 'https://twitch.tv/GroovePointe')
  const channelName = activeStream.url.split('/').pop();

  return (
    <section className="current-streams">
      <div className="section-content">
        <h2>LIVE NOW</h2>
        <div className="stream-card">
          <div className="stream-container">
            <iframe
              src={`https://player.twitch.tv/?channel=${channelName}&parent=${window.location.hostname}`}
              frameBorder="0"
              allowFullScreen={true}
              scrolling="no"
              height="378"
              width="620"
              title={`${activeStream.name} live stream`}
            />
            <div className="stream-info">
              <h3>{activeStream.name}</h3>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Link
                  href={activeStream.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    color: '#bf94ff',
                    textDecoration: 'none',
                    padding: '6px 12px',
                    borderRadius: '4px',
                    border: '1px solid #bf94ff',
                    transition: 'all 0.2s ease',
                    width: 'fit-content',
                    minWidth: '120px',
                    justifyContent: 'center',
                    '&:hover': {
                      color: '#000',
                      backgroundColor: '#bf94ff',
                      textDecoration: 'none',
                      border: '1px solid #bf94ff'
                    }
                  }}
                >
                  <FaTwitch size={20} />
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '0.875rem'
                    }}
                  >
                    Watch Live
                  </Typography>
                </Link>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CurrentStreams;
