import { useState, useEffect } from 'react';
import { API_ENDPOINTS, fetchFromApi } from '../config/api';
import '../styles/Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [submitStatus, setSubmitStatus] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const checkPermission = async () => {
      try {
        const response = await fetchFromApi('/api/check-inquiries-permission');
        setHasPermission(response.has_permission);
      } catch (err) {
        console.error('Error checking permissions:', err);
        setHasPermission(false);
      }
    };
    checkPermission();
  }, []);

  // If no permission, don't render the form
  if (!hasPermission) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await fetchFromApi(API_ENDPOINTS.inquiries, {
        method: 'POST',
        body: JSON.stringify(formData)
      });
      
      // Check for message in response
      if (response.message && response.inquiry) {
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
        setSubmitStatus('success');
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setSubmitting(false);
      // Clear status after 5 seconds
      setTimeout(() => {
        setSubmitStatus(null);
      }, 5000);
    }
  };

  if (submitStatus === 'error') {
    return (
      <section className="contact-section" id="contact">
        <div className="contact-container">
          <h2>Contact</h2>
          <div className="status-message error">
            Sorry, there was an error sending your message. Please try again.
          </div>
          <ContactForm 
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
        </div>
      </section>
    );
  }

  return (
    <section className="contact-section" id="contact">
      <div className="contact-container">
        <h2>Contact</h2>
        <p className="contact-description">
          Have a question or interested in booking? We'd love to hear from you.
        </p>
        
        {submitStatus === 'success' && (
          <div className="status-message success">
            Message sent successfully! We'll get back to you soon.
          </div>
        )}

        <form className="contact-form" onSubmit={handleSubmit}>
          {/* Subject - Full Width */}
          <div className="form-group full-width">
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Subject"
              required
              maxLength="200"
              disabled={submitting}
              autoComplete="off"
            />
          </div>

          {/* Contact Information */}
          <div className="form-group">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              required
              disabled={submitting}
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              required
              disabled={submitting}
              autoComplete="off"
            />
          </div>

          {/* Message Body */}
          <div className="form-group full-width">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your Message"
              required
              rows="4"
              disabled={submitting}
            />
          </div>

          <div className="form-group button-container">
            <button 
              type="submit" 
              className={`submit-button ${submitting ? 'loading' : ''}`}
              disabled={submitting}
            >
              {submitting ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

const ContactForm = ({ formData, handleChange, handleSubmit, submitting }) => {
  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      {/* Subject - Full Width */}
      <div className="form-group full-width">
        <input
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          placeholder="Subject"
          required
          maxLength="200"
          disabled={submitting}
          autoComplete="off"
        />
      </div>

      {/* Contact Information */}
      <div className="form-group">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Your Name"
          required
          disabled={submitting}
          autoComplete="off"
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Your Email"
          required
          disabled={submitting}
          autoComplete="off"
        />
      </div>

      {/* Message Body */}
      <div className="form-group full-width">
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Your Message"
          required
          rows="4"
          disabled={submitting}
        />
      </div>

      <div className="form-group button-container">
        <button 
          type="submit" 
          className={`submit-button ${submitting ? 'loading' : ''}`}
          disabled={submitting}
        >
          {submitting ? 'Sending...' : 'Send Message'}
        </button>
      </div>
    </form>
  );
};

export default Contact;
