let isYouTubeApiReady = false;
let youtubeApiCallbacks = [];

export const loadYouTubeApi = (callback) => {
  if (window.YT && isYouTubeApiReady) {
    callback();
    return;
  }

  youtubeApiCallbacks.push(callback);

  if (!window.YT) {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  if (!window.onYouTubeIframeAPIReady) {
    window.onYouTubeIframeAPIReady = () => {
      isYouTubeApiReady = true;
      youtubeApiCallbacks.forEach(cb => cb());
      youtubeApiCallbacks = [];
    };
  }
};
