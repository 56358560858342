// API configuration
const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5000';
const isDevelopment = import.meta.env.MODE === 'development';

// Add debugging
const logApiCall = (endpoint, options) => {
  if (isDevelopment) {
    console.log(`Making API call to: ${endpoint}`, {
      baseUrl: API_URL,
      fullEndpoint: endpoint,
      ...options
    });
  }
};

// Default fetch options based on environment
const defaultOptions = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  mode: 'cors'
};

// API Endpoints - paths are the same in both environments
export const API_ENDPOINTS = {
  upcomingEvents: `/api/upcoming-events`,
  pastEvents: `/api/past-events`,
  artists: `/api/artists`,
  inquiries: `/api/inquiries`,
  checkInquiriesPermission: `/api/check-inquiries-permission`,
  releases: `/api/releases`,
  health: `/api/health`,
  checkTwitchStreams: `/api/check-twitch-streams`,
  twitchStream: `/api/twitch/stream`
};

// Add request helper with error handling
export const fetchFromApi = async (endpoint, customOptions = {}) => {
  // In development, use the full URL with API_URL
  // In production, use relative paths (Nginx handles routing)
  const fullUrl = isDevelopment ? `${API_URL}${endpoint}` : endpoint;
  
  const options = {
    ...defaultOptions,
    ...customOptions,
    headers: {
      ...defaultOptions.headers,
      ...(customOptions.headers || {})
    }
  };

  logApiCall(fullUrl, options);
  
  try {
    const response = await fetch(fullUrl, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
};

export default API_ENDPOINTS;
