import React, { useEffect, useState, useRef } from 'react';
import { FaInstagram, FaTwitch, FaSoundcloud, FaYoutube, FaSpotify, FaBandcamp, FaMixcloud, FaFacebookF, FaTwitter, FaGlobe } from 'react-icons/fa';
import { loadYouTubeApi } from '../utils/youtubeApi';
import { loadSoundCloudApi } from '../utils/soundCloudApi';
import { setActivePlayer } from '../utils/audioManager';
import { getRandomArtistImage, preloadImage } from '../utils/imageUtils';
import '../styles/ArtistCard.css';

const ArtistCard = ({ artist }) => {
  const { name, bio, social_links, talents, featured_mix } = artist;
  const [embedError, setEmbedError] = useState(null);
  const [artistImage, setArtistImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const audioContextRef = useRef(null);

  const socialIcons = {
    instagram: FaInstagram,
    twitch: FaTwitch,
    soundcloud: FaSoundcloud,
    youtube: FaYoutube,
    spotify: FaSpotify,
    bandcamp: FaBandcamp,
    mixcloud: FaMixcloud,
    facebook: FaFacebookF,
    twitter: FaTwitter,
    website: FaGlobe
  };

  const getYouTubeVideoId = (url) => {
    if (!url) return null;
    
    try {
      if (url.includes('youtu.be')) {
        return url.split('youtu.be/')[1].split('?')[0];
      }
      
      if (url.includes('youtube.com')) {
        const urlParams = new URLSearchParams(url.split('?')[1]);
        return urlParams.get('v');
      }
      
      return null;
    } catch (err) {
      console.error('Error parsing YouTube URL:', err);
      return null;
    }
  };

  const handleUserInteraction = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      console.log('AudioContext created');
    } else {
      audioContextRef.current.resume().then(() => {
        console.log('AudioContext resumed');
      });
    }
  };

  useEffect(() => {
    const loadRandomImage = async () => {
      try {
        const imageUrl = await getRandomArtistImage(name.toLowerCase());
        if (imageUrl) {
          await preloadImage(imageUrl);
          setArtistImage(imageUrl);
          setImageLoaded(true);
        }
      } catch (error) {
        console.error('Failed to load artist image:', error);
      }
    };
    
    loadRandomImage();
  }, [name]);

  useEffect(() => {
    if (!featured_mix) return;

    const youtubeId = getYouTubeVideoId(featured_mix);
    
    if (youtubeId) {
      loadYouTubeApi(() => createYouTubePlayer(youtubeId));
    } else {
      loadSoundCloudApi(() => {
        const playerId = `soundcloud-player-${name.replace(/\s+/g, '-')}`;
        const playerElement = document.getElementById(playerId);
        
        if (!playerElement) {
          console.error(`Player element with ID ${playerId} not found.`);
          setEmbedError('SoundCloud player element not found.');
          return;
        }

        const player = SC.Widget(playerElement);
        
        player.bind(SC.Widget.Events.READY, () => {
          console.log('SoundCloud player loaded');
          setActivePlayer({
            pause: () => player.pause()
          }, 'soundcloud');
        });

        player.bind(SC.Widget.Events.ERROR, (error) => {
          console.error('SoundCloud player error:', error);
          setEmbedError('Failed to load SoundCloud player');
        });
      });
    }
  }, [featured_mix, name]);

  const createYouTubePlayer = (videoId) => {
    try {
      if (!window.YT || !videoId) {
        throw new Error('YouTube API not ready or invalid video ID');
      }

      new window.YT.Player(`youtube-player-${name.replace(/\s+/g, '-')}`, {
        height: '100%',
        width: '100%',
        videoId: videoId,
        playerVars: {
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
          controls: 1,
          color: 'white',
          iv_load_policy: 3,
          disablekb: 1,
          autoplay: 0,
          playsinline: 1
        }
      });
    } catch (error) {
      console.error('Error creating YouTube player:', error);
      setEmbedError('Failed to load YouTube player');
    }
  };

  const renderSocialLinks = () => {
    return Object.entries(social_links || {}).map(([platform, url]) => {
      if (!url) return null;
      const Icon = socialIcons[platform.toLowerCase()];
      return Icon ? (
        <a
          key={platform}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
          title={platform.charAt(0).toUpperCase() + platform.slice(1)}
        >
          <Icon />
        </a>
      ) : null;
    });
  };

  const renderFeaturedMix = () => {
    if (!featured_mix || embedError) return null;

    console.log('Featured Mix URL:', featured_mix);

    const youtubeId = getYouTubeVideoId(featured_mix);
    console.log('YouTube ID:', youtubeId);

    if (youtubeId) {
      return (
        <div className="youtube-player">
          <div id={`youtube-player-${name.replace(/\s+/g, '-')}`}></div>
        </div>
      );
    } else {
      const encodedUrl = encodeURIComponent(featured_mix);
      const playerId = `soundcloud-player-${name.replace(/\s+/g, '-')}`;
      
      return (
        <div className="soundcloud-player" style={{
          backgroundImage: `url(${artistImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          height: '166px',
          borderRadius: '8px',
          overflow: 'hidden'
        }}>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }} />
          <iframe
            id={playerId}
            title="SoundCloud Player"
            width="100%"
            height="166"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            style={{ position: 'relative', zIndex: 1 }}
            src={`https://w.soundcloud.com/player/?url=${encodedUrl}&color=%23ff5500&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=true`}
          />
        </div>
      );
    }
  };

  return (
    <div className="artist-card" onClick={handleUserInteraction}>
      <div className="artist-image">
        {imageLoaded ? (
          <img src={artistImage} alt={name} />
        ) : (
          <div className="artist-image-placeholder" />
        )}
      </div>
      <div className="artist-content">
        <div className="artist-info">
          <h1>{name}</h1>
          <p className="artist-talents">{Array.isArray(talents) ? talents.join(', ') : talents}</p>
          <p className="artist-bio">{bio}</p>
          <div className="artist-social-links">
            {renderSocialLinks()}
          </div>
        </div>
        {featured_mix && !embedError && (
          <>
            {renderFeaturedMix()}
          </>
        )}
        {embedError && <p>{embedError}</p>}
      </div>
    </div>
  );
};

export default ArtistCard;
