import React from 'react';
import { useUpcomingEvents } from '../hooks/useUpcomingEvents';
import UpcomingEventCard from './UpcomingEventCard';
import '../styles/FeaturedSection.css';

const FeaturedSection = () => {
  const { upcomingWithinWeek } = useUpcomingEvents();

  if (!upcomingWithinWeek || upcomingWithinWeek.length === 0) {
    return null;
  }

  return (
    <section id="featured-events" className="featured-events">
      <div className="section-content">
        <h2>
          <span className="pulse">THIS WEEK</span>
        </h2>
        <div className="events-grid">
          {upcomingWithinWeek.map((event) => (
            <UpcomingEventCard 
              key={event.event_id}
              event={event}
              pulseDateTime={true}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturedSection;
