import { useState, useEffect } from 'react';
import { API_ENDPOINTS, fetchFromApi } from '../config/api';

export const useUpcomingEvents = () => {
  const [upcomingWithinWeek, setUpcomingWithinWeek] = useState([]);
  const [upcomingBeyondWeek, setUpcomingBeyondWeek] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const data = await fetchFromApi(API_ENDPOINTS.upcomingEvents, {
          method: 'GET',
          mode: 'cors'
        });
        
        if (!data || data.error) {
          console.error('Error fetching events:', data?.error);
          setUpcomingBeyondWeek([]);
          return;
        }

        const currentDate = new Date(); // Use dynamic current date
        const oneWeekFromNow = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        
        // Group events by event_name
        const eventsByName = data.reduce((groups, event) => {
          const name = event.event_name;
          if (!groups[name]) {
            groups[name] = [];
          }
          groups[name].push(event);
          return groups;
        }, {});

        // Keep track of event series that appear in the featured section
        const featuredEventNames = new Set();

        // Create grouped events array for within week
        const withinWeekEvents = Object.entries(eventsByName).map(([eventName, events]) => {
          const sortedEvents = events
            .sort((a, b) => new Date(a.event_start) - new Date(b.event_start))
            .filter(event => {
              const eventDate = new Date(event.event_start);
              // Only include events that are within a week and after current date
              return eventDate <= oneWeekFromNow && eventDate >= currentDate;
            });

          if (sortedEvents.length > 0) {
            featuredEventNames.add(eventName); // Add to featured set
            return {
              ...sortedEvents[0],
              upcoming_dates: sortedEvents
            };
          }
          return null;
        }).filter(Boolean); // Remove any null entries

        // Create grouped events array for beyond a week, excluding featured event series
        const beyondWeekEvents = Object.entries(eventsByName).map(([eventName, events]) => {
          // Skip if this event series is already in featured section
          if (featuredEventNames.has(eventName)) {
            return null;
          }

          const sortedEvents = events
            .sort((a, b) => new Date(a.event_start) - new Date(b.event_start))
            .filter(event => {
              const eventDate = new Date(event.event_start);
              // Only include events that are more than a week away
              return eventDate > oneWeekFromNow;
            })
            .slice(0, 3); // Limit to 3 upcoming dates per event type

          if (sortedEvents.length > 0) {
            return {
              ...sortedEvents[0],
              upcoming_dates: sortedEvents
            };
          }
          return null;
        }).filter(Boolean); // Remove any null entries

        setUpcomingWithinWeek(withinWeekEvents);
        setUpcomingBeyondWeek(beyondWeekEvents);
        
      } catch (err) {
        console.error('Error in fetchEvents:', err);
        setUpcomingBeyondWeek([]);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  return { upcomingWithinWeek, upcomingBeyondWeek: upcomingBeyondWeek, loading };
};

export default useUpcomingEvents;
