/**
 * Fetches the list of available images for an artist
 * @param {string} artistName - The name of the artist (lowercase)
 * @returns {Promise<string[]>} Array of image filenames
 */
const fetchArtistImages = async (artistName) => {
  try {
    const response = await fetch(`/api/artist-images/${artistName}`);
    if (!response.ok) {
      throw new Error('Failed to fetch images');
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching images for ${artistName}:`, error);
    return [];
  }
};

/**
 * Gets a random image URL for a specific artist
 * @param {string} artistName - The name of the artist (lowercase)
 * @returns {Promise<string|null>} A promise that resolves with the URL of a random image for the artist
 */
export const getRandomArtistImage = (artistName) => {
  return new Promise((resolve) => {
    const attemptedNumbers = new Set();
    const maxImages = 5;

    function tryNextImage() {
      if (attemptedNumbers.size >= maxImages) {
        console.warn(`No images found for artist: ${artistName}`);
        resolve(null);
        return;
      }

      // Get a random number that hasn't been tried yet
      let randomNum;
      do {
        randomNum = Math.floor(Math.random() * maxImages) + 1;
      } while (attemptedNumbers.has(randomNum));
      
      attemptedNumbers.add(randomNum);
      
      const imagePath = `/images/artists/${artistName}/${artistName}-${randomNum}.jpg`;
      const img = new Image();
      
      img.onload = () => resolve(imagePath);
      img.onerror = () => tryNextImage();
      
      img.src = imagePath;
    }

    tryNextImage();
  });
};

/**
 * Preloads an image and returns a promise that resolves when the image is loaded
 * @param {string} imageUrl - The URL of the image to preload
 * @returns {Promise} A promise that resolves when the image is loaded
 */
export const preloadImage = (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(imageUrl);
    img.onerror = () => reject(new Error(`Failed to load image: ${imageUrl}`));
    img.src = imageUrl;
  });
};

export { fetchArtistImages };
