import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import About from './components/About'
import Artists from './components/Artists'
import UpcomingEvents from './components/UpcomingEvents'
import PastEvents from './components/PastEvents'
import Releases from './components/Releases'
import Contact from './components/Contact'
import Footer from './components/Footer'
import CurrentStreams from './components/CurrentStreams'
import FeaturedSection from './components/FeaturedSection'
import './styles/App.css'

function MainContent() {
  return (
    <>
      <Navbar />
      <main>
        <section id="hero">
          <Hero />
        </section>
        <FeaturedSection />
        <CurrentStreams />
        <section id="about">
          <About />
        </section>
        <section id="artists">
          <Artists />
        </section>
        <section id="upcoming-events">
          <UpcomingEvents />
        </section>
        <section id="past-events">
          <PastEvents />
        </section>
        <section id="releases">
          <Releases />
        </section>
        <section id="contact">
          <Contact />
        </section>
      </main>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainContent />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
