import React from 'react';
import { useUpcomingEvents } from '../hooks/useUpcomingEvents';
import UpcomingEventCard from './UpcomingEventCard';
import '../styles/UpcomingEvents.css';

const UpcomingEvents = () => {
  const { upcomingBeyondWeek, loading } = useUpcomingEvents();

  if (loading) {
    return null;
  }

  if (!upcomingBeyondWeek || !upcomingBeyondWeek.length) {
    return null;
  }

  return (
    <section id="upcoming-events" className="upcoming-events">
      <div className="section-content">
        <h2>Upcoming Events</h2>
        <div className="events-grid">
          {upcomingBeyondWeek.map((event) => (
            <UpcomingEventCard 
              key={event.event_id}
              event={event}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default UpcomingEvents;
