import { useState, useEffect } from 'react'
import { API_ENDPOINTS, fetchFromApi } from '../config/api'
import ArtistCard from './ArtistCard'
import { defaultArtists } from '../data/defaultArtists'
import '../styles/Artists.css'

const Artists = () => {
  const [artists, setArtists] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        setLoading(true)
        const data = await fetchFromApi(API_ENDPOINTS.artists)
        console.log('Artists data:', data)
        setArtists(data)
      } catch (err) {
        console.error('Error fetching artists:', err)
        console.log('Using default artists data')
        setArtists(defaultArtists)
      } finally {
        setLoading(false)
      }
    }

    fetchArtists()
  }, [])

  if (loading) {
    return null;
  }

  if (!artists.length) {
    return null;
  }

  return (
    <section id="artists" className="artists">
      <div className="section-content">
        <h2>Artists</h2>
        <div className="artists-grid">
          {artists.map((artist) => (
            <ArtistCard key={artist.id} artist={artist} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Artists
