export const loadSoundCloudApi = (callback) => {
  const tag = document.createElement('script');
  tag.src = 'https://w.soundcloud.com/player/api.js';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  tag.onload = () => {
    callback();
  };
};
