let activePlayer = null;
let activeService = null;

export const setActivePlayer = (player, service) => {
  if (activePlayer && activeService === service) {
    activePlayer.pause();
  }
  activePlayer = player;
  activeService = service;
};

export const pauseActivePlayer = () => {
  if (activePlayer) {
    activePlayer.pause();
    activePlayer = null;
    activeService = null;
  }
};
