import '../styles/About.css'

const About = () => {
  return (
    <section id="about" className="about">
      <div className="section-content">
        <h2>ABOUT</h2>
        <div className="about-content">
          <p>
            Hidden away from the vibrant streets of Chicago,
            The Lost Loft is a one-of-a-kind underground sanctuary
            for lovers of authentic house and techno music. 
            Our intimate events serve as a hub for a passionate community 
            of DJs, producers, photographers, and visual artists—all 
            united by a shared dedication to creating unforgettable 
            experiences exploring the spaces between the records. 
            At The Lost Loft, we bridge the sounds of Chicago, Detroit, 
            and beyond, bringing them to life through expertly crafted 
            vinyl and digital sets, original music productions, and 
            creative visual art.
          </p>
        </div>
      </div>
    </section>
  )
}

export default About
