export const defaultArtists = [
  {
    "bio": null,
    "featured_mix": "https://soundcloud.com/groovepointe/groovepointe-deeper-101124?si=f1a5096a74fa43cbb4f1579c7b9e42ed&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "id": 1,
    "location": "Chicago",
    "name": "GroovePointe",
    "photo_links": [
      "/images/artists/groovepointe-1.jpg"
    ],
    "social_links": {
      "bandcamp": "https://groovepointe.bandcamp.com/",
      "instagram": "https://instagram.com/GroovePointe",
      "mixcloud": "https://www.mixcloud.com/GroovePointe/",
      "soundcloud": "https://soundcloud.com/groovepointe",
      "twitch": "https://twitch.tv/GroovePointe",
      "youtube": "https://youtube.com/@GroovePointe"
    },
    "talents": [
      "DJ",
      "Producer",
      "Visual Artist"
    ]
  },
  {
    "bio": null,
    "featured_mix": "https://soundcloud.com/ferluno01/a-little-bit-of-everything-001?si=1562a3b28e014952be4b374afb40a34f&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "id": 2,
    "location": "Chicago",
    "name": "ferlUno",
    "photo_links": [
      "/images/artists/ferluno-1.jpg"
    ],
    "social_links": {
      "instagram": "https://instagram.com/ferlUno01",
      "soundcloud": "https://soundcloud.com/ferluno01",
      "twitch": "https://twitch.tv/ferluno",
      "youtube": "https://youtube.com/ferluno01"
    },
    "talents": [
      "DJ"
    ]
  },
  {
    "bio": null,
    "featured_mix": "https://youtu.be/tiNvhbp53xY?si=168AgZeCO0CGrG2Q",
    "id": 3,
    "location": "Chicago",
    "name": "PlumSauce",
    "photo_links": [
      "/images/artists/plumsauce-1.jpg"
    ],
    "social_links": {
      "instagram": "https://instagram.com/plumsaucedj",
      "twitch": "https://twitch.tv/plumsaucedj"
    },
    "talents": [
      "DJ",
      "Producer"
    ]
  }
];
