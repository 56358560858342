import React, { useEffect, useState, useRef } from 'react';
import { loadYouTubeApi } from '../utils/youtubeApi';
import { setActivePlayer } from '../utils/audioManager';
import '../styles/PastEventCard.css';

const PastEventCard = ({ event }) => {
  const [embedError, setEmbedError] = useState(null);
  const audioContextRef = useRef(null);

  const getYouTubeVideoId = (url) => {
    if (!url) return null;
    try {
      if (url.includes('youtu.be')) {
        return url.split('youtu.be/')[1].split('?')[0];
      }
      if (url.includes('youtube.com')) {
        const urlParams = new URLSearchParams(url.split('?')[1]);
        return urlParams.get('v');
      }
      return null;
    } catch (err) {
      console.error('Error parsing YouTube URL:', err);
      return null;
    }
  };

  const handleUserInteraction = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      console.log('AudioContext created');
    } else {
      audioContextRef.current.resume().then(() => {
        console.log('AudioContext resumed');
      });
    }
  };

  useEffect(() => {
    if (!event.youtube_link) return;

    const youtubeId = getYouTubeVideoId(event.youtube_link);
    if (youtubeId) {
      loadYouTubeApi(() => createYouTubePlayer(youtubeId));
    }
  }, [event.youtube_link]);

  const createYouTubePlayer = (videoId) => {
    try {
      if (!window.YT || !videoId) {
        throw new Error('YouTube API not ready or invalid video ID');
      }

      new window.YT.Player(`youtube-player-${event.event_id}`, {
        height: '100%',
        width: '100%',
        videoId: videoId,
        playerVars: {
          modestbranding: 1,
          rel: 0,
          showinfo: 1,
          controls: 1,
          color: 'white',
          iv_load_policy: 3,
          disablekb: 1,
          autoplay: 0,
          playsinline: 1
        },
        events: {
          onReady: (event) => {
            console.log('YouTube player ready');
            setActivePlayer({
              pause: () => event.target.pauseVideo()
            }, 'youtube');
          },
          onStateChange: (event) => {
            if (event.data === window.YT.PlayerState.PLAYING) {
              console.log('YouTube player started playing');
              setActivePlayer({
                pause: () => event.target.pauseVideo()
              }, 'youtube');
            }
          }
        }
      });
    } catch (error) {
      console.error('Error creating YouTube player:', error);
      setEmbedError('Failed to load YouTube player');
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const renderVideo = () => {
    if (!event.youtube_link || embedError) return null;

    const youtubeId = getYouTubeVideoId(event.youtube_link);
    
    if (youtubeId) {
      return (
        <div className="video-container">
          <div className="youtube-player">
            <div id={`youtube-player-${event.event_id}`}></div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="past-event-card" onClick={handleUserInteraction}>
      {renderVideo()}
      <div className="past-event-content">
        <div className="past-event-header">
          <h3>{`${event.event_name} ${String(event.event_id).padStart(3, '0')}`}</h3>
          <div className="event-meta">
            <div className="event-artists">
              {event.featured_artist_names.length === 1 && event.featured_artist_names[0] === 'TBA' 
                ? '' 
                : event.featured_artist_names.join(' • ')}
            </div>
            <div className="event-date">
              {formatDate(event.event_start)}
              {event.description && ' • '}
              {event.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastEventCard;
