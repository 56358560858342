import React, { useState, useEffect } from 'react';
import { API_ENDPOINTS, fetchFromApi } from '../config/api'
import PastEventCard from './PastEventCard'
import '../styles/PastEvents.css'

const PastEvents = () => {
  const [pastEvents, setPastEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const getYouTubeVideoId = (url) => {
    if (!url) return null;
    
    try {
      if (url.includes('youtu.be')) {
        return url.split('youtu.be/')[1].split('?')[0];
      }
      
      if (url.includes('youtube.com')) {
        const urlParams = new URLSearchParams(url.split('?')[1]);
        return urlParams.get('v');
      }
      
      return null;
    } catch (err) {
      console.error('Error parsing YouTube URL:', err);
      return null;
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const data = await fetchFromApi(API_ENDPOINTS.pastEvents, {
          method: 'GET',
          mode: 'cors'
        });

        if (!data || data.error) {
          console.error('Error fetching past events:', data?.error);
          setPastEvents([]);
          return;
        }

        const currentDate = new Date();
        const filteredEvents = data
          .filter(event => new Date(event.event_start) < currentDate)
          .sort((a, b) => new Date(b.event_start) - new Date(a.event_start))
          .slice(0, 6);

        console.log('Filtered events:', filteredEvents);
        setPastEvents(filteredEvents);

      } catch (err) {
        console.error('Error fetching past events:', err);
        setPastEvents([]);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return null;
  }

  if (!pastEvents.length) {
    return null;
  }

  return (
    <section id="past-events" className="past-events">
      <div className="section-content">
        <h2>Past Events</h2>
        <div className="past-events-grid">
          {pastEvents.map((event, index) => (
            <PastEventCard key={index} event={event} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PastEvents;
